import React from 'react';
import './App.css';
import Navi from './components/navi';
import Footer from './components/footer';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Home from './pages/Home';

function App() {
  return (
    <Container>
      <Router>
        <Row>
          <Navi/>
        </Row>
        <Row style={{maxHeight: '70vh', overflow:'scroll'}}>
          <Switch>
            {/* <Route path="/about">
            </Route> */}

            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </Row>
        <Row>
          <Footer/>
        </Row>
      </Router>

    </Container>
  );
}

export default App;
