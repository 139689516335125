import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import essar from '../../assets/essar.jpeg';
import bestwestern from '../../assets/bestwestern.png';
import maxone from '../../assets/maxone.jpeg';
import patra from '../../assets/patra.jpeg';

import { GetQuoteModal } from '../../components/navi';

const clients = [ patra, essar, bestwestern, maxone ];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.show = this.show.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  show() {
    this.setState({
      showModal: true,
    })
  }

  handleClose() {
    this.setState({
      showModal: false,
    })
  }
  
  render() {
    return(
      <Container style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: '1000px',
          paddingTop: '100px',
          paddingLeft: '20px',
          height: '100%',
        }}>
        <div style={{
          // height: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <h1 
            style={{maxWidth: '750px'}}
          >
            We are the preferred solution for Housekeeping and Security services
          </h1>
          <h5>Or even when you need the Facility Management issues to be taken care of,<br/> we are where the bugs stop.</h5>
          <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-around', width: '100%'}}>
            <Button variant="dark" size="lg" onClick={this.show} style={{ margin: '20px', maxWidth: '400px'}}>Get a Quote</Button>
          </div>
        </div>

        <GetQuoteModal
          show={this.state.showModal}
          handleClose={this.handleClose}
        />

        <div style={{
          width: '100%'
        }}>
          <br/>
          <hr/>
          {/* <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,.2)',
          }}>
          </div> */}
          <Container style={{
            maxWidth: '1000px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: 0,
          }}>
            <h3>Trusted By</h3>
          </Container>
          <Container style={{
            maxWidth: '1000px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            padding: 0,
          }}>
            {
              clients.map((item) => {
                return <img src={item} alt="client" width="150px" style={{margin: '20px'}}/>
              })
            }
            <h4>and more...</h4>
          </Container>
        </div>
      </Container>
    )
  }
}

export default Home;