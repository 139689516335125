import React, { Component } from 'react';


class OfficeMap extends Component {
  render() {
    return (
      <div style={{ height: '100%', width: '100%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.7094812037785!2d106.89417782924453!3d-6.152455666341243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f547357feb2b%3A0x55a3afaafd90fcf1!2sPT%20Pro%20Guard%20International!5e0!3m2!1sen!2sid!4v1577643564580!5m2!1sen!2sid" title="office map" width="100%" height="100%" frameborder="0" style={{border:0}} allowFullScreen=""></iframe>
      </div>
    );
  }
}

export default OfficeMap;