import React from 'react';
import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import img from '../../assets/building.jpg';

import proguardlogo from '../../assets/proguard.png';

import OfficeMap from '../officemap';
import { device } from '../../utils/device';

const FooterRow = styled.div`
  width: 100vw;
  overflow: hidden;

  @media ${device.mobileS} {
    position: relative;
    max-height: 400px;
  }

  @media ${device.laptop} {
    position: fixed;
    max-height: 30vh;
    bottom: 0;
    left: 0;
  }
`

const NoOverflowCol = styled(Col)`
  overflow-x: hidden;
`

const Footer = (props) => {
  return (
    <FooterRow>
      <OverlayedImage
        imgsrc={img}
      />
    </FooterRow>
  )
}

const Overlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  padding: 40px;
`

const WhiteTitle = styled.p`
  font-size: 2rem;
  color: white;
  font-family: 'roboto';
`

const StyledOuterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

const ProguardImg = styled.img`
  width: 250px;

  @media ${device.mobileL} {
    width: 400px;
  }

  @media (max-height: 670px) {
    width: 200px;
  }
`;

const OverlayedImage = (props) => {
  return(
    <div style={{
      objectFit: 'cover',
      margin: 0,
    }}>
      <img src={props.imgsrc} alt="sample 1"/>
      <Overlay>
        <StyledOuterDiv>
          <div style={{width: '100%', height: '100%'}}>
            <OfficeMap/>
          </div>
          <div style={{width: '100%', paddingLeft: '20px', color: 'white'}}>
            {/* <h2>Company Details:</h2> */}
            <ProguardImg src={proguardlogo} width='400px' alt="logo proguard"/> <br/>
            PT Proguard international<br/>
            Email: <a href="mailto:powerandpro@gmail.com?Subject=Asking%20For%20Quotes" target="_top">powerandpro@gmail.com</a> <br/>
            Mobile/WA: O89 8888 5988 <br/>
          </div>
        </StyledOuterDiv>
      </Overlay>
    </div>
  )
}

export default Footer