import React, { useState } from 'react';
import {
  Link,
} from "react-router-dom";
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import logo from '../../assets/powerpro_logo.png';
import './nav.css';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  margin: 0 20px;

  &:hover{
    color: black;
    text-decoration: none;
    opacity: 0.6;
  }
`;

const StyledMenuItem = styled(Nav.Item)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledCollapsedNav = styled(Navbar.Collapse)`
  display: flex;
  justify-content: flex-end;
`

const Navi = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Col>
      <Navbar expand="md" fixed="top" style={{background: 'white',maxWidth: '1000px', margin: 'auto'}}>
        <Navbar.Brand href='/'>
          <img src={logo} alt="powerpro logo" width="200px"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <StyledCollapsedNav id="basic-navbar-nav">
          <Nav>
            {/* <StyledMenuItem>
              <StyledLink to="/about">
                About Us
              </StyledLink>
            </StyledMenuItem> */}

            {/* <StyledMenuItem>
              <StyledLink to="/">
                Home
              </StyledLink>
            </StyledMenuItem> */}

            <StyledMenuItem>
              <Button variant="dark" onClick={handleShow}>
                Get a quote
              </Button>
            </StyledMenuItem>
          </Nav>
        </StyledCollapsedNav>
      </Navbar>
      
      <GetQuoteModal
        show={show}
        handleClose={handleClose}
      />

    </Col>
  );
}

export class GetQuoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(name){
    return (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;

      this.setState({
        [name]: value
      });
    }
  }

  submit(e) {
    // e.preventDefault()
    // form checking
    // 1. valid email

    // submit the form to some 3rd party handler
    // document.getElementById("quote_form").submit()
    let dt = new URLSearchParams(new FormData(document.getElementById("quote_form"))).toString()
    // let dt = new FormData(document.getElementById("quote_form"))
    // let dt = document.getElementById("quote_form").serializeArray()

    fetch("https://script.google.com/macros/s/AKfycbz9L1HIp1oh-GoCSSnWKUy3QSqVoslIJcJ9Z6bYkkkb0NjzMcZ_/exec?"+dt)
    .then(res => res.json())
    .then(body => {
      alert("Successfully submitted your request!")
      this.props.handleClose();
    })
    .catch(err => {
      alert("something went wrong, please try resubmitting")
      console.log(err)
    })
  }

  render() {
    const { show, handleClose } = this.props
    return(
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get a quote from our experts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form 
            id='quote_form'
            onSubmit={(e) => this.submit(e)}
          >
            <Form.Group controlId="formBasicFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" name="first_name" placeholder="Budi" onChange={this.handleInputChange('firstName')}/>
            </Form.Group>

            <Form.Group controlId="formBasicLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control type="text" name="last_name" placeholder="Prasetya" onChange={this.handleInputChange('lastName')}/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="setyabudi@gmail.com" onChange={this.handleInputChange('email')}/>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicCompanyName">
              <Form.Label>Company name</Form.Label>
              <Form.Control type="text" name="company_name" placeholder="PT Telco Indonesia" onChange={this.handleInputChange('companyName')}/>
            </Form.Group>

            <Form.Group controlId="formBasicCompanyName">
              <Form.Label>Job Title</Form.Label>
              <Form.Control type="text" name="job_title" placeholder="Direktur Utama" onChange={this.handleInputChange('jobTitle')}/>
            </Form.Group>

            <Form.Group controlId="formBasicPhoneNumber">
              <Form.Label>Phone number</Form.Label>
              <Form.Control type="text" name="phone" placeholder="08*******" onChange={this.handleInputChange('phoneNumber')}/>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="dark" onClick={this.submit}>
            Get quote
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default Navi;